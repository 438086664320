@import '~antd/dist/antd.css';

.ant-layout {
  background: #fff !important;
}

.content {
  padding: 0 50px;
  margin-top: 64px;
}

@media (max-width: 480px) {
  .ant-picker-panels {
    flex-direction: column;
  }
}
